.loadingCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.loading1 {
    animation-name: loading1-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading2 {
    animation-name: loading2-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading3 {
    animation-name: loading3-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading4 {
    animation-name: loading4-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading5 {
    animation-name: loading5-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading6 {
    animation-name: loading6-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading7 {
    animation-name: loading7-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading8 {
    animation-name: loading8-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading9 {
    animation-name: loading9-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.loading10 {
    animation-name: loading10-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: inline-block;
}

@-webkit-keyframes loading1-animation {
    0% {transform: scale(1.2,1.2); opacity: 1.0;}
    10% {transform: scale(1,1); opacity: 0.8;}
    20% {transform: scale(1,1); opacity: 0.7;}
    30% {transform: scale(1,1); opacity: 0.6;}
    40% {transform: scale(1,1); opacity: 0.5;}
    50% {transform: scale(1,1); opacity: 0.4;}
    60% {transform: scale(1,1); opacity: 0.3;}
    70% {transform: scale(1,1); opacity: 0.2;}
    80% {transform: scale(1,1); opacity: 0.1;}
    90% {transform: scale(1,1); opacity: 0.0;}
}
@keyframes loading1-animation {
    0% {transform: scale(1.2,1.2); opacity: 1.0;}
    10% {transform: scale(1,1); opacity: 0.8;}
    20% {transform: scale(1,1); opacity: 0.7;}
    30% {transform: scale(1,1); opacity: 0.6;}
    40% {transform: scale(1,1); opacity: 0.5;}
    50% {transform: scale(1,1); opacity: 0.4;}
    60% {transform: scale(1,1); opacity: 0.3;}
    70% {transform: scale(1,1); opacity: 0.2;}
    80% {transform: scale(1,1); opacity: 0.1;}
    90% {transform: scale(1,1); opacity: 0.0;}
}

@-webkit-keyframes loading2-animation {
    0% {transform: scale(1,1); opacity: 0.0;}
    10% {transform: scale(1.2,1.2); opacity: 1.0;}
    20% {transform: scale(1,1); opacity: 0.8;}
    30% {transform: scale(1,1); opacity: 0.7;}
    40% {transform: scale(1,1); opacity: 0.6;}
    50% {transform: scale(1,1); opacity: 0.5;}
    60% {transform: scale(1,1); opacity: 0.4;}
    70% {transform: scale(1,1); opacity: 0.3;}
    80% {transform: scale(1,1); opacity: 0.2;}
    90% {transform: scale(1,1); opacity: 0.1;}
    
}
@keyframes loading2-animation {
    0% {transform: scale(1,1); opacity: 0.0;}
    10% {transform: scale(1.2,1.2); opacity: 1.0;}
    20% {transform: scale(1,1); opacity: 0.8;}
    30% {transform: scale(1,1); opacity: 0.7;}
    40% {transform: scale(1,1); opacity: 0.6;}
    50% {transform: scale(1,1); opacity: 0.5;}
    60% {transform: scale(1,1); opacity: 0.4;}
    70% {transform: scale(1,1); opacity: 0.3;}
    80% {transform: scale(1,1); opacity: 0.2;}
    90% {transform: scale(1,1); opacity: 0.1;}
}

@-webkit-keyframes loading3-animation {
    0% {transform: scale(1,1); opacity: 0.1;}
    10% {transform: scale(1,1); opacity: 0.0;}
    20% {transform: scale(1.2,1.2); opacity: 1.0;}
    30% {transform: scale(1,1); opacity: 0.8;}
    40% {transform: scale(1,1); opacity: 0.7;}
    50% {transform: scale(1,1); opacity: 0.6;}
    60% {transform: scale(1,1); opacity: 0.5;}
    70% {transform: scale(1,1); opacity: 0.4;}
    80% {transform: scale(1,1); opacity: 0.3;}
    90% {transform: scale(1,1); opacity: 0.2;}
}
@keyframes loading3-animation {
    0% {transform: scale(1,1); opacity: 0.1;}
    10% {transform: scale(1,1); opacity: 0.0;}
    20% {transform: scale(1.2,1.2); opacity: 1.0;}
    30% {transform: scale(1,1); opacity: 0.8;}
    40% {transform: scale(1,1); opacity: 0.7;}
    50% {transform: scale(1,1); opacity: 0.6;}
    60% {transform: scale(1,1); opacity: 0.5;}
    70% {transform: scale(1,1); opacity: 0.4;}
    80% {transform: scale(1,1); opacity: 0.3;}
    90% {transform: scale(1,1); opacity: 0.2;}
}

@-webkit-keyframes loading4-animation {
    0% {transform: scale(1,1); opacity: 0.2;}
    10% {transform: scale(1,1); opacity: 0.1;}
    20% {transform: scale(1,1); opacity: 0.0;}
    30% {transform: scale(1.2,1.2); opacity: 1.0;}
    40% {transform: scale(1,1); opacity: 0.8;}
    50% {transform: scale(1,1); opacity: 0.7;}
    60% {transform: scale(1,1); opacity: 0.6;}
    70% {transform: scale(1,1); opacity: 0.5;}
    80% {transform: scale(1,1); opacity: 0.4;}
    90% {transform: scale(1,1); opacity: 0.3;}
}
@keyframes loading4-animation {
    0% {transform: scale(1,1); opacity: 0.2;}
    10% {transform: scale(1,1); opacity: 0.1;}
    20% {transform: scale(1,1); opacity: 0.0;}
    30% {transform: scale(1.2,1.2); opacity: 1.0;}
    40% {transform: scale(1,1); opacity: 0.8;}
    50% {transform: scale(1,1); opacity: 0.7;}
    60% {transform: scale(1,1); opacity: 0.6;}
    70% {transform: scale(1,1); opacity: 0.5;}
    80% {transform: scale(1,1); opacity: 0.4;}
    90% {transform: scale(1,1); opacity: 0.3;}
}

@-webkit-keyframes loading5-animation {
    0% {transform: scale(1,1); opacity: 0.3;}
    10% {transform: scale(1,1); opacity: 0.2;}
    20% {transform: scale(1,1); opacity: 0.1;}
    30% {transform: scale(1,1); opacity: 0.0;}
    40% {transform: scale(1.2,1.2); opacity: 1.0;}
    50% {transform: scale(1,1); opacity: 0.8;}
    60% {transform: scale(1,1); opacity: 0.7;}
    70% {transform: scale(1,1); opacity: 0.6;}
    80% {transform: scale(1,1); opacity: 0.5;}
    90% {transform: scale(1,1); opacity: 0.4;}
}
@keyframes loading5-animation {
    0% {transform: scale(1,1); opacity: 0.3;}
    10% {transform: scale(1,1); opacity: 0.2;}
    20% {transform: scale(1,1); opacity: 0.1;}
    30% {transform: scale(1,1); opacity: 0.0;}
    40% {transform: scale(1.2,1.2); opacity: 1.0;}
    50% {transform: scale(1,1); opacity: 0.8;}
    60% {transform: scale(1,1); opacity: 0.7;}
    70% {transform: scale(1,1); opacity: 0.6;}
    80% {transform: scale(1,1); opacity: 0.5;}
    90% {transform: scale(1,1); opacity: 0.4;}
}

@-webkit-keyframes loading6-animation {
    0% {transform: scale(1,1); opacity: 0.4;}
    10% {transform: scale(1,1); opacity: 0.3;}
    20% {transform: scale(1,1); opacity: 0.2;}
    30% {transform: scale(1,1); opacity: 0.1;}
    40% {transform: scale(1,1); opacity: 0.0;}
    50% {transform: scale(1.2,1.2); opacity: 1.0;}
    60% {transform: scale(1,1); opacity: 0.8;}
    70% {transform: scale(1,1); opacity: 0.7;}
    80% {transform: scale(1,1); opacity: 0.6;}
    90% {transform: scale(1,1); opacity: 0.5;}
}
@keyframes loading6-animation {
    0% {transform: scale(1,1); opacity: 0.4;}
    10% {transform: scale(1,1); opacity: 0.3;}
    20% {transform: scale(1,1); opacity: 0.2;}
    30% {transform: scale(1,1); opacity: 0.1;}
    40% {transform: scale(1,1); opacity: 0.0;}
    50% {transform: scale(1.2,1.2); opacity: 1.0;}
    60% {transform: scale(1,1); opacity: 0.8;}
    70% {transform: scale(1,1); opacity: 0.7;}
    80% {transform: scale(1,1); opacity: 0.6;}
    90% {transform: scale(1,1); opacity: 0.5;}
}

@-webkit-keyframes loading7-animation {
    0% {transform: scale(1,1); opacity: 0.5;}
    10% {transform: scale(1,1); opacity: 0.4;}
    20% {transform: scale(1,1); opacity: 0.3;}
    30% {transform: scale(1,1); opacity: 0.2;}
    40% {transform: scale(1,1); opacity: 0.1;}
    50% {transform: scale(1,1); opacity: 0.0;}
    60% {transform: scale(1.2,1.2); opacity: 1.0;}
    70% {transform: scale(1,1); opacity: 0.8;}
    80% {transform: scale(1,1); opacity: 0.7;}
    90% {transform: scale(1,1); opacity: 0.6;}
}
@keyframes loading7-animation {
    0% {transform: scale(1,1); opacity: 0.5;}
    10% {transform: scale(1,1); opacity: 0.4;}
    20% {transform: scale(1,1); opacity: 0.3;}
    30% {transform: scale(1,1); opacity: 0.2;}
    40% {transform: scale(1,1); opacity: 0.1;}
    50% {transform: scale(1,1); opacity: 0.0;}
    60% {transform: scale(1.2,1.2); opacity: 1.0;}
    70% {transform: scale(1,1); opacity: 0.8;}
    80% {transform: scale(1,1); opacity: 0.7;}
    90% {transform: scale(1,1); opacity: 0.6;}
}

@-webkit-keyframes loading8-animation {
    0% {transform: scale(1,1); opacity: 0.6;}
    10% {transform: scale(1,1); opacity: 0.5;}
    20% {transform: scale(1,1); opacity: 0.4;}
    30% {transform: scale(1,1); opacity: 0.3;}
    40% {transform: scale(1,1); opacity: 0.2;}
    50% {transform: scale(1,1); opacity: 0.1;}
    60% {transform: scale(1,1); opacity: 0.0;}
    70% {transform: scale(1.2,1.2); opacity: 1.0;}
    80% {transform: scale(1,1); opacity: 0.8;}
    90% {transform: scale(1,1); opacity: 0.7;}
}
@keyframes loading8-animation {
    0% {transform: scale(1,1); opacity: 0.6;}
    10% {transform: scale(1,1); opacity: 0.5;}
    20% {transform: scale(1,1); opacity: 0.4;}
    30% {transform: scale(1,1); opacity: 0.3;}
    40% {transform: scale(1,1); opacity: 0.2;}
    50% {transform: scale(1,1); opacity: 0.1;}
    60% {transform: scale(1,1); opacity: 0.0;}
    70% {transform: scale(1.2,1.2); opacity: 1.0;}
    80% {transform: scale(1,1); opacity: 0.8;}
    90% {transform: scale(1,1); opacity: 0.7;}
}

@-webkit-keyframes loading9-animation {
    0% {transform: scale(1,1); opacity: 0.7;}
    10% {transform: scale(1,1); opacity: 0.6;}
    20% {transform: scale(1,1); opacity: 0.5;}
    30% {transform: scale(1,1); opacity: 0.4;}
    40% {transform: scale(1,1); opacity: 0.3;}
    50% {transform: scale(1,1); opacity: 0.2;}
    60% {transform: scale(1,1); opacity: 0.1;}
    70% {transform: scale(1,1); opacity: 0.0;}
    80% {transform: scale(1.2,1.2); opacity: 1.0;}
    90% {transform: scale(1,1); opacity: 0.8;}
}
@keyframes loading9-animation {
    0% {transform: scale(1,1); opacity: 0.7;}
    10% {transform: scale(1,1); opacity: 0.6;}
    20% {transform: scale(1,1); opacity: 0.5;}
    30% {transform: scale(1,1); opacity: 0.4;}
    40% {transform: scale(1,1); opacity: 0.3;}
    50% {transform: scale(1,1); opacity: 0.2;}
    60% {transform: scale(1,1); opacity: 0.1;}
    70% {transform: scale(1,1); opacity: 0.0;}
    80% {transform: scale(1.2,1.2); opacity: 1.0;}
    90% {transform: scale(1,1); opacity: 0.8;}
}

@-webkit-keyframes loading10-animation {
    0% {transform: scale(1,1); opacity: 0.8;}
    10% {transform: scale(1,1); opacity: 0.7;}
    20% {transform: scale(1,1); opacity: 0.6;}
    30% {transform: scale(1,1); opacity: 0.5;}
    40% {transform: scale(1,1); opacity: 0.4;}
    50% {transform: scale(1,1); opacity: 0.3;}
    60% {transform: scale(1,1); opacity: 0.2;}
    70% {transform: scale(1,1); opacity: 0.1;}
    80% {transform: scale(1,1); opacity: 0.0;}
    90% {transform: scale(1.2,1.2); opacity: 1.0;}
}
@keyframes loading10-animation {
    0% {transform: scale(1,1); opacity: 0.8;}
    10% {transform: scale(1,1); opacity: 0.7;}
    20% {transform: scale(1,1); opacity: 0.6;}
    30% {transform: scale(1,1); opacity: 0.5;}
    40% {transform: scale(1,1); opacity: 0.4;}
    50% {transform: scale(1,1); opacity: 0.3;}
    60% {transform: scale(1,1); opacity: 0.2;}
    70% {transform: scale(1,1); opacity: 0.1;}
    80% {transform: scale(1,1); opacity: 0.0;}
    90% {transform: scale(1.2,1.2); opacity: 1.0;}
}