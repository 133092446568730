clear {
    animation-name: clear;
    animation-duration: 2s;
    animation-iteration-count: 1;
    display: inline-block;
}
    
.fail {
    animation-name: beat;
    animation-duration: 1s;
    animation-iteration-count: 1;
    display: inline-block;
}
    
.pass {
    animation-name: beat;
    animation-duration: 1s;
    animation-iteration-count: 1;
    display: inline-block;
}

.checkin {
    animation-name: checkin;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: inline-block;
}
    
.results {
    animation-name: results;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: inline-block;
}
    
.store {
    animation-name: store;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.resultspulsepass {
    animation-name: resultspulse;
    animation-delay: 500ms;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.resultspulsefail {
    animation-name: resultspulse;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    display: inline-block;
}

.ciarrow {
    animation-name: ciarrow;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: inline-block;
}   

.ci {
    animation-name: ci;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: inline-block;
}
    
.step1 {
    position: relative;
    animation-name: step1;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
    
.step2 {
    position: relative;
    animation-name: step2;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
    
.step3 {
    position: relative;
    animation-name: step3;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
    
.step4 {
    position: relative;
    animation-name: step4;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
    
@-webkit-keyframes clear {
    0%, 100% { opacity: 1;}
    0.1% { opacity: 0;}
    25% {opacity: 0.25;}
}
@keyframes clear {
    0%, 100% { opacity: 1;}
    1% { opacity: 0;}
    25% {opacity: 0.25;}
}
    
@-webkit-keyframes step1 {
    0% {opacity: 1}
    23% {opacity: 1}
    24% {opacity: 0.25}
    100% {opacity: 0.25}
}
@keyframes step1 {
    0% {opacity: 1}
    23% {opacity: 1}
    24% {opacity: 0.25}
    100% {opacity: 0.25}
}
    
@-webkit-keyframes step2 {
    0% {opacity: 0.25}
    24% {opacity: 0.25}
    25% {opacity: 1}
    48% {opacity: 1}
    49% {opacity: 0.25}
    100% {opacity: 0.25}
}
@keyframes step2 {
    0% {opacity: 0.25}
    24% {opacity: 0.25}
    25% {opacity: 1}
    48% {opacity: 1}
    49% {opacity: 0.25}
    100% {opacity: 0.25}
}
    
@-webkit-keyframes step3 {
    0% {opacity: 0.25}
    49% {opacity: 0.25}
    50% {opacity: 1}
    73% {opacity: 1}
    74% {opacity: 0.25}
    100% {opacity: 0.25}
}
@keyframes step3 {
    0% {opacity: 0.25}
    49% {opacity: 0.25}
    50% {opacity: 1}
    73% {opacity: 1}
    74% {opacity: 0.25}
    100% {opacity: 0.25}
}
    
@-webkit-keyframes step4 {
    0% {opacity: 0.25}
    74% {opacity: 0.25}
    75% {opacity: 1}
}
@keyframes step4 {
    0% {opacity: 0.25}
    74% {opacity: 0.25}
    75% {opacity: 1}
}

@-webkit-keyframes checkin {
    0% {transform: scale(1,1); opacity: 0}
    10% {transform: scale(1.1, 1.1);}
    15% {transform: scale(1, 1); opacity: 1}
    20% {transform: scale(1.1, 1.1);}
    25%{transform: scale(1, 1);}
}
@keyframes checkin {
    0% {transform: scale(1,1); opacity: 0}
    10% {transform: scale(1.1, 1.1); opacity: 1}
    15% {transform: scale(1, 1);}
    20% {transform: scale(1.1, 1.1);}
    25%{transform: scale(1, 1);}
}    
    
@-webkit-keyframes ci {
    0% {transform: rotate(0deg); opacity: 0}
    20% {transform: rotate(0deg); opacity: 0}
    25% {transform: rotate(0deg); opacity: 1}
    50% {transform: rotate(360deg);}
    100% {transform: rotate(360deg);}
}
@keyframes ci {
    0% {transform: rotate(0deg); opacity: 0}
    20% {transform: rotate(0deg); opacity: 0}
    25% {transform: rotate(0deg); opacity: 1}
    50% {transform: rotate(360deg);}
    100% {transform: rotate(360deg);}
}

@-webkit-keyframes ciarrow {
    0% {transform: scale(1,1); opacity: 0}
    20% {transform: scale(1.1, 1.1); opacity: 0;}
    25% {transform: scale(1, 1); opacity: 1}
    50% {transform: scale(1.1, 1.1);}
    100%{transform: scale(1, 1);}
}
@keyframes ciarrow {
    0% {transform: scale(1,1); opacity: 0;}
    20% {transform: scale(1.1, 1.1); opacity: 0;}
    25% {transform: scale(1, 1); ; opacity: 1}
    50% {transform: scale(1.1, 1.1);}
    100%{transform: scale(1, 1);}
} 
    
@-webkit-keyframes results {
    0% {transform: scale(1,1); opacity: 0;}
    49% {transform: scale(1,1); opacity: 0;}
    50% {transform: scale(1,1);opacity: 1;}
    55% {transform: scale(1.1, 1.1)}
    60% {transform: scale(1, 1);}
    65% {transform: scale(1.1, 1.1);}
    75%{transform: scale(1, 1);}
}
@keyframes results {
    0% {transform: scale(1,1); opacity: 0;}
    45% {transform: scale(1,1); opacity: 0;}
    50% {transform: scale(1,1); opacity: 1;}
    55% {transform: scale(1.1, 1.1)}
    60% {transform: scale(1, 1);}
    65% {transform: scale(1.1, 1.1);}
    75%{transform: scale(1, 1);}
}
    
@-webkit-keyframes store {
    0% {transform: scale(1,1); opacity: 0;}
    70% {transform: scale(1,1); opacity: 0;}
    75% {transform: scale(1,1); opacity: 1;}
    80% {transform: scale(1.15, 1.15)}
    100%{transform: scale(1, 1);}
}
@keyframes store {
    0% {transform: scale(1,1); opacity: 0;}
    70% {transform: scale(1,1); opacity: 0;}
    75% {transform: scale(1,1); opacity: 1;}
    80% {transform: scale(1.15, 1.15)}
    100%{transform: scale(1, 1);}
}

@-webkit-keyframes resultspulse {
    0% {transform: scale(1,1);}
    49% {transform: scale(1,1)}
    50% {transform: scale(1,1)}
    55% {transform: scale(1.15, 1.15)}
    57% {transform: scale(1.05, 1.05);}
    60% {transform: scale(1.15, 1.15);}
    70%{transform: scale(1, 1);}
}
@keyframes resultspulse {
    0% {transform: scale(1,1);}
    49% {transform: scale(1,1)}
    50% {transform: scale(1,1)}
    55% {transform: scale(1.15, 1.15)}
    57% {transform: scale(1.05, 1.05);}
    60% {transform: scale(1.15, 1.15);}
    70%{transform: scale(1, 1);}
}